// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Dashboard from './Dashboard';
import ViewRecord from './ViewRecord'; 

function App() {
  return (
    <Routes> {/* Routes should be used here */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/view-record/:serialNumber" element={<ViewRecord />} />

    </Routes>
  );
}

export default App;
  