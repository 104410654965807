import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Make sure this is imported
import './Login.css';
import { toast, ToastContainer } from 'react-toastify';

const api_url = process.env.REACT_APP_API_URL;


function Login() {
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate(); // Make sure this is used
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('handleSubmit is being called'); // Debugging line
  
    if (!isLogin && password !== confirmPassword) {
      toast.error("Passwords don't match!"); 
      setErrorMessage("Passwords don't match!");
      return;
    }
    setIsLoading(true); 
    setErrorMessage('');

    const endpoint = isLogin 
      // ? `${apiUrl}/login` 
      // : `${apiUrl}/signup`;
   ? `${api_url}/login`
      :`${api_url}/signup`;
    const body = isLogin 
      ? { email, password }
      : { username, email, password, address, phone_number: phoneNumber, role };
  
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(body),
        credentials: 'include', // Include credentials in the request
      });
  
      console.log('Response status:', response.status); // Log the response status
  
      if (response.ok) {
        const data = await response.json();
        console.log('Response data:', data); // Log the response data
  
        // Set the token in a cookie or localStorage
        document.cookie = `token=${data.token}; path=/; max-age=3600; Secure; SameSite=None`; // Token will expire in 1 hour
        // or use localStorage if you prefer
        // localStorage.setItem('token', data.token);
        toast.success(isLogin ? 'Login successful!' : 'Signup successful!'); // Show success toast

        console.log(data.message);
        console.log('Redirecting to /dashboard'); // Add this line before navigate('/dashboard')
  
        navigate('/dashboard'); // Use navigate for redirection
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'An error occurred. Please try again.');
        toast.error(errorData.message || 'An error occurred. Please try again.'); // Show error toast
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
      toast.error('An error occurred. Please try again.'); // Show error toast
      console.error('Error:', error); // Log the error for debugging
    }
   finally {
    setIsLoading(false); // Stop loading
  }
  };

  return (
    <div className="login-container">
      <h2>{isLogin ? 'Sign In' : 'Sign Up'}</h2>
      <form onSubmit={handleSubmit}>
        {!isLogin && (
          <>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="role">Role</label>
              <input
                type="text"
                id="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              />
            </div>
          </>
        )}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
  <label htmlFor="password">Password</label>
  <div className="password-container">
    <input
      type={showPassword ? 'text' : 'password'}
      id="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      required
    />
    <button
      type="button"
      onClick={() => setShowPassword(!showPassword)}
      className="password-toggle-button"
      title={showPassword ? 'Hide Password' : 'Show Password'}
    >
      {showPassword ? (
        <i className="fas fa-eye-slash"></i>
      ) : (
        <i className="fas fa-eye"></i>
      )}
    </button>
  </div>
</div>
        {!isLogin && (
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        )}
    <button type="submit" disabled={isLoading}>
      {isLoading ? (
        <div className="loading-spinner"></div>
      ) : (
        isLogin ? 'Login' : 'Sign Up'
      )}
    </button>
    {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}
    </form>
      <button
        className="footer-link"
        onClick={() => setIsLogin(!isLogin)}
      >
        {isLogin ? "Don't have an account? Sign Up" : 'Already have an account? Log In'}
      </button>
      <ToastContainer />
    </div>
  );
}

export default Login;
