import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, X, FileText } from 'lucide-react';
import './Upload.css';
import { useNavigate } from 'react-router-dom';
const api_url = process.env.REACT_APP_API_URL;

const CSVUpload = ({ onFileChange, onNext, previewData, onClose }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isUploading, setIsUploading] = useState(false);  // Track upload state
  const [error, setError] = useState(null);  // Track error state
  const [selectedFile, setSelectedFile] = useState(null);  // Store the actual File object
  const navigate = useNavigate();

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file?.name.endsWith('.csv')) {
      setFileName(file.name);
      setSelectedFile(file);  // Store the actual file
      onFileChange({ target: { files: [file] } });
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setSelectedFile(file);  // Store the actual file
      onFileChange(e);  // Update previewData with parsed file data (if applicable)
    }
  };

  const handleStoreData = async () => {
    if (!selectedFile) {
      setError('Please select a CSV file.');
      return;
    }
  
    setIsUploading(true);
    setError(null);  // Clear any previous errors
  
    const formData = new FormData();
  
    // Use the actual file selected, not previewData
    if (selectedFile && selectedFile instanceof File) {
      formData.append('file', selectedFile);  // Correctly append the file under the 'file' key
    } else {
      setError('No file to upload.');
      setIsUploading(false);
      return;
    }

    console.log("formData", formData);
    console.log('FormData contents:');
    formData.forEach((value, key) => {
      console.log(`${key}:`, value); // Logs the key and the file object
    
      // If the value is a file, log specific properties like name, size, and type
      if (value instanceof File) {
        console.log('File details:');
        console.log('Name:', value.name);       // File name
        console.log('Size:', value.size);       // File size in bytes
        console.log('Type:', value.type);       // File MIME type
      }
    });

    try {
      const response = await fetch(`${api_url}/upload_csv`, {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload the CSV file.');
      }
  
      const data = await response.json();
      console.log('Success:', data); // Handle success
      onNext();  // Proceed to the next step
      navigate(0);  // This will re-trigger the current route without hard-refreshing the page

    } catch (error) {
      setError(error.message);  // Set error message if request fails
    } finally {
      setIsUploading(false);  // Reset uploading state
    }
  };
  
  return (
    <div className="csv-upload-card">
      <div className="csv-upload-card-header">
        <h4 className="csv-upload-card-title">Upload Meter Records</h4>
        <button
          className="csv-close-button"
          aria-label="Close"
          onClick={onClose}
        >
          <X size={20} />
        </button>
      </div>

      <div
        className={`csv-drop-zone ${isDragging ? 'dragging' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          accept=".csv"
          id="file-upload"
          onChange={handleFileSelect}
          className="csv-file-input"
          aria-label="Upload CSV"
        />
        
        {!fileName ? (
          <label htmlFor="file-upload" className="csv-upload-prompt">
            <Upload size={48} className="csv-upload-icon" />
            <span className="csv-upload-text">
              Drop your CSV file here or click to browse
            </span>
            <span className="csv-upload-subtext">
              Supports CSV files only
            </span>
          </label>
        ) : (
          <div className="csv-file-selected">
            <FileText size={24} className="csv-file-icon" />
            <span className="csv-filename">{fileName}</span>
            <button
              className="csv-remove-file"
              onClick={() => {
                setFileName('');
                setSelectedFile(null);  // Clear the selected file
                onFileChange({ target: { files: [] } });
              }}
            >
              <X size={16} />
            </button>
          </div>
        )}
      </div>

      {previewData && previewData.length > 0 && (
        <div className="csv-preview-section">
          <h5 className="csv-preview-title">CSV Preview</h5>
          <div className="csv-preview-table-container">
            <table className="csv-preview-table">
              <thead>
                <tr>
                  {Object.keys(previewData[0]).map((key, index) => (
                    <th key={index}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {previewData.slice(0, 5).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.values(row).map((value, colIndex) => (
                      <td key={colIndex}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="csv-action-buttons">
        <button
          className={`csv-next-button ${!fileName || isUploading ? 'disabled' : ''}`}
          onClick={handleStoreData}
          disabled={isUploading || !fileName}
        >
          {isUploading ? 'Uploading...' : 'Store Data'}
        </button>
      </div>

      {error && <div className="csv-upload-error">{error}</div>}
    </div>
  );
};

CSVUpload.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  previewData: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CSVUpload;
