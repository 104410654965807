import React from 'react';
import Modal from './Modal'; // Import your Modal component if you have one

const ViewModal = ({ record, onClose }) => {
  if (!record) return null; // Avoid rendering if no record is selected

  return (
    <Modal onClose={onClose} title="View Record">
      <div className="record-details">
        <p><strong>Serial Number:</strong> {record.serial_number}</p>
        <p><strong>Meter Type:</strong> {record.meter_type}</p>
        <p><strong>Installation Date:</strong> {record.installation_date}</p>
        <p><strong>Site Name:</strong> {record.site_name}</p>
        <p><strong>Old Serial Number:</strong> {record.old_sr_no}</p>
        <p><strong>Manufacture Date:</strong> {record.manufacture_date}</p>
        <p><strong>Warranty Status:</strong> {record.warranty_status}</p>
        <p><strong>Delivery Date:</strong> {record.delivery_date}</p>
        <p><strong>Incoming Quantity:</strong> {record.in_qty}</p>
        <p><strong>Outgoing Quantity:</strong> {record.out_qty}</p>
        <p><strong>Outgoing From:</strong> {record.out_from}</p>
        <p><strong>Remarks:</strong> {record.remarks}</p>
        <p><strong>Complaint:</strong> {record.complaint}</p>
        <p><strong>Gate Pass No:</strong> {record.gate_pass_no}</p>
        <p><strong>Product Name:</strong> {record.product_name}</p>
        <p><strong>Chalan Number:</strong> {record.chalan_no}</p>
      </div>
    </Modal>
  );
};

export default ViewModal;
