import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import './ViewRecord.css';
const api_url = process.env.REACT_APP_API_URL;

const InfoItem = ({ label, value, icon }) => {
    const displayValue = value === undefined || value === null ? 'N/A' : value;
    return (
        <div className="record-info-item">
            <span className="info-label">{label}</span>
            <div className="value-container">
                <span className="value-text">
                    {icon && <FontAwesomeIcon icon={icon} className="icon" />}
                    {displayValue}
                </span>
            </div>
        </div>
    );
};

const ViewRecord = () => {
    const { serialNumber } = useParams();
    const location = useLocation();
    const { record } = location.state || {};
    const [activeTab, setActiveTab] = useState('basic');
    const [meters, setMeters] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Reset active tab if record changes (e.g., navigating to a different record)
        setActiveTab('basic');
    }, [record]);

    // Fetch meter data from the API
    useEffect(() => {
        const fetchMeters = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${api_url}/meters`,{
                    method: 'GET',
                    credentials: 'include',
                });
                const data = await response.json();
                console.log(data)
                setMeters(data);
            } catch (error) {
                console.error('Error fetching meters:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMeters();
    }, []);

    if (!record) {
        return (
            <div className="view-record-container">
                <p className="no-record-message">No record found for serial number: {serialNumber}</p>
            </div>
        );
    }

    const recordSections = {
        basic: [
            { label: 'Serial Number', value: record.serial_number || 'Enter serial number' },
            { label: 'Meter Type', value: record.meter_type || 'Enter meter type' },
            { label: 'Installation Date', value: record.installation_date || 'dd-mm-yyyy', icon: faCalendarAlt },
            { label: 'Site Name', value: record.site_name || 'Enter site name' },
            { label: 'Delivery Status', value: record.delivery_status || 'Enter delivery status' },
            {
              label: 'Status',
              value:
                record.status === 'active' ? (
                  <FontAwesomeIcon icon={faCheckCircle} className="icon success" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} className="icon error" />
                ),
            },
          ],
        
        additional: [
            { label: 'Old Serial Number', value: record.old_sr_no || 'Enter old serial number' },
            { label: 'Manufacture Date', value: record.manufacture_date || 'dd-mm-yyyy', icon: faCalendarAlt },
            { label: 'Warranty Status', value: record.warranty_status || 'Enter warranty status' },
            { label: 'Delivery Date', value: record.delivery_date || 'dd-mm-yyyy', icon: faCalendarAlt },
            { label: 'Received From', value: record.received_from || 'N/A' },
          ],
        inventory: [
            { label: 'Incoming Quantity', value: record.incoming_quantity || 'Enter incoming quantity' },
            { label: 'Outgoing Quantity', value: record.outgoing_quantity || 'Enter outgoing quantity' },
            { label: 'Outgoing From', value: record.outgoing_from || 'Enter outgoing from' },
            { label: 'Remarks', value: record.remarks || 'Enter remarks' },
            { label: 'Complaint', value: record.complaint || 'N/A' },
          ],
          gatePass: [
            { label: 'Gate Pass No', value: record.gate_pass_no || 'Enter gate pass number' },
            { label: 'Product Name', value: record.product_name || 'Enter product name' },
            { label: 'Chalan Number', value: record.chalan_number || 'N/A' },
          ],
    };

    return (
        <div className="view-record-container">
            {/* Sidebar */}
            <aside className="sidebar">
  <h2 className="sidebar-title">
    {loading ? (
      <div className="skeleton skeleton-title"></div>
    ) : (
      'Meters'
    )}
  </h2>
  {loading ? (
    <ul className="serial-list">
      {[...Array(5)].map((_, index) => (
        <li key={index} className="skeleton skeleton-item"></li>
      ))}
    </ul>
  ) : meters.length > 0 ? (
    <ul className="serial-list">
      {meters.map((meter) => (
        <li
          key={meter.serial_number}
          className={`serial-item ${meter.serial_number === serialNumber ? 'active' : ''}`}
        >
          {meter.serial_number}
        </li>
      ))}
    </ul>
  ) : (
    <p className="no-records">No records found</p>
  )}
</aside>

            {/* Main Content */}
            <div className="main-content">
                <header className="view-record-header">
                    <h1 className="title">Record Details</h1>
                </header>

                <div className="tabs">
                    {Object.keys(recordSections).map((section) => (
                        <button
                            key={section}
                            className={`tab-button ${activeTab === section ? 'active' : ''}`}
                            onClick={() => setActiveTab(section)}
                        >
                            {section.charAt(0).toUpperCase() + section.slice(1)}
                        </button>
                    ))}
                </div>

                <div className="tab-content">
                    {recordSections[activeTab] && (
                        <section className="record-card">
                            <h2 className="card-title">{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} Information</h2>
                            <div className="info-group">
                                {recordSections[activeTab].map((item, index) => (
                                    <InfoItem key={`${item.label}-${index}`} {...item} />
                                ))}
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewRecord;
